import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { Nav, Dropdown, RadioGroup, Radio } from "rsuite";
import { EraIcon } from "../../styles/Icon.js";
import { getUserInfo, getUserRoles } from "../../azure/MSGraphService.js";
import { appRoleMap } from "../../azure/authConfig.js";
import { getActiveRole, setActiveRole } from "./CookieManager.js";
import { msGraphRequest } from "../../azure/authConfig.js";

import { useMsal } from "@azure/msal-react";
import { clearCookies } from "./CookieManager.js";

export const SignInButton = () => {

    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(msGraphRequest).catch(e => {
            //console.log(e);
        });
    }

    return (
        <Nav.Item onClick={() => handleLogin()} >
            Sign in
        </Nav.Item>
    )
}

export const SignOutButton = () => {

    const { instance } = useMsal();

    const handleLogout = () => {
        clearCookies()
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        }).then(() => { });
    }

    return (
        <Nav.Item onClick={() => handleLogout()} icon={<EraIcon faName="right-from-bracket" />}>
            Sign out
        </Nav.Item>
    )
}

export const UserMenu = (props) => {

    const [userInfo, setUserInfo] = useState(props.userInfo)
    const [userRoles, setUserRoles] = useState(props.userRoles)
    const [isAuthenticated, setIsAuthenticated] = useState(props.isAuthenticated)
    const [activeRole, setActiveRole] = useState() //props.activeRole;
    const activeRoleName = appRoleMap.get(activeRole) ? appRoleMap.get(activeRole) : "User"

    const handleOnChangeRadioSelect = props.handleOnChangeRadioSelect;

    useEffect(() => {

        if (props.isAuthenticated) {

            setUserInfo(props.userInfo);
            setUserRoles(props.userRoles);
            setActiveRole(props.activeRole);

            setIsAuthenticated(props.isAuthenticated);

        }

    }, [props]);

    return (

        <Nav.Menu icon={<EraIcon faName="user" />} >

            {isAuthenticated && (

                <>
                    <Nav.Item disabled active icon={<EraIcon faName="user" />}>
                        {userInfo?.displayName}
                    </Nav.Item>


                    <Nav.Menu title={"Role: " + activeRoleName} openDirection={"start"}>





                        {
                            userRoles?.value.map(role => (
                                <Nav.Item
                                    key={role.appRoleId}
                                    active={activeRole === role.appRoleId}
                                    onSelect={() => { handleOnChangeRadioSelect(role.appRoleId) }}>
                                    {appRoleMap.get(role.appRoleId)}
                                </Nav.Item>
                            ))
                        }



                    </Nav.Menu>
                </>

            )}

            {
                isAuthenticated ?
                    <SignOutButton /> :
                    <>
                        <Nav.Item as={'a'} href="https://srm-portal.powerappsportals.com/">
                            Reset Password
                        </Nav.Item>

                        <SignInButton />
                    </>
            }

        </Nav.Menu>
    )
}
