export const BASE_URI = "http://data.europa.eu/949/";

export const a = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#type';

export const OWL = {
    owlObjectProperty: 'http://www.w3.org/2002/07/owl#ObjectProperty',
    owlDataTypeProperty: 'http://www.w3.org/2002/07/owl#DatatypeProperty',
    owlFunctionalProperty: 'http://www.w3.org/2002/07/owl#FunctionalProperty',
    unionOf: 'http://www.w3.org/2002/07/owl#unionOf'
}

export const RDF = {
    first: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#first',
    rest: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#rest',
    nil: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#nil',
    type: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#type'
}

export const XSD = {
    boolean: "http://www.w3.org/2001/XMLSchema#boolean",
    integer: "http://www.w3.org/2001/XMLSchema#integer",
    string: "http://www.w3.org/2001/XMLSchema#string",
    double: "http://www.w3.org/2001/XMLSchema#double",
    decimal: "http://www.w3.org/2001/XMLSchema#decimal"
}

export const RDFS = {
    label: 'http://www.w3.org/2000/01/rdf-schema#label',
    description: 'http://www.w3.org/2000/01/rdf-schema#description',
    range: 'http://www.w3.org/2000/01/rdf-schema#range',
    comment: 'http://www.w3.org/2000/01/rdf-schema#comment',
    domain: 'http://www.w3.org/2000/01/rdf-schema#domain'
}

export const SKOS = {
    inScheme: 'http://www.w3.org/2004/02/skos/core#inScheme',
    prefLabel: 'http://www.w3.org/2004/02/skos/core#prefLabel',
    definition: 'http://www.w3.org/2004/02/skos/core#definition',
    closeMatch: 'http://www.w3.org/2004/02/skos/core#closeMatch',
    exactMatch: 'http://www.w3.org/2004/02/skos/core#exactMatch'
}

export const GEOSPARQL = {
    asWKT: 'http://www.opengis.net/ont/geosparql#asWKT',
    hasGeometry: 'http://www.opengis.net/ont/geosparql#hasGeometry'
}

export const WGS84 = {
    lat: 'http://www.w3.org/2003/01/geo/wgs84_pos#lat',
    long: 'http://www.w3.org/2003/01/geo/wgs84_pos#long',
    location: 'http://www.w3.org/2003/01/geo/wgs84_pos#location'
}

export const ERA = {
    OperationalPoint: `https://data-interop.era.europa.eu/OperationalPoint`,
    SectionOfLine: `https://data-interop.era.europa.eu/SectionOfLine`,
    NetElement: `https://data-interop.era.europa.eu/NetElement`,
    NetRelation: `https://data-interop.era.europa.eu/NetRelation`,
    rinfIndex: `https://data-interop.era.europa.eu/rinfIndex`,
    uopid: `https://data-interop.era.europa.eu/uopid`,
    opName: `https://data-interop.era.europa.eu/opName`,
    opStart: `https://data-interop.era.europa.eu/opStart`,
    opEnd: `https://data-interop.era.europa.eu/opEnd`,
    opType: `https://data-interop.era.europa.eu/opType`,
    imCode: `https://data-interop.era.europa.eu/imCode`,
    inCountry: `https://data-interop.era.europa.eu/inCountry`,
    lineReference: `https://data-interop.era.europa.eu/lineReference`,
    lineNationalId: `https://data-interop.era.europa.eu/lineNationalId`,
    tafTapCode: `https://data-interop.era.europa.eu/tafTAPCode`,
    hasAbstraction: `https://data-interop.era.europa.eu/hasAbstraction`,
    hasImplementation: `https://data-interop.era.europa.eu/hasImplementation`,
    VehicleType: `https://data-interop.era.europa.eu/VehicleType`,
    Vehicle: `https://data-interop.era.europa.eu/Vehicle`,
    trainDetectionSystem: `https://data-interop.era.europa.eu/trainDetectionSystem`,
    trainDetectionSystemType: `https://data-interop.era.europa.eu/trainDetectionSystemType`,
    gaugingProfile: `https://data-interop.era.europa.eu/gaugingProfile`,
    axleBearingConditionMonitoring: `https://data-interop.era.europa.eu/axleBearingConditionMonitoring`,
    hasHotAxleBoxDetector: `https://data-interop.era.europa.eu/hasHotAxleBoxDetector`,
    railInclination: `https://data-interop.era.europa.eu/railInclination`,
    wheelSetGauge: `https://data-interop.era.europa.eu/wheelSetGauge`,
    minimumWheelDiameter: `https://data-interop.era.europa.eu/minimumWheelDiameter`,
    minimumHorizontalRadius: `https://data-interop.era.europa.eu/minimumHorizontalRadius`,
    minimumTemperature: `https://data-interop.era.europa.eu/minimumTemperature`,
    maximumTemperature: `https://data-interop.era.europa.eu/maximumTemperature`,
    contactLineSystem: `https://data-interop.era.europa.eu/contactLineSystem`,
    contactLineSystemType: `https://data-interop.era.europa.eu/contactLineSystemType`,
    energySupplySystem: `https://data-interop.era.europa.eu/energySupplySystem`,
    maxCurrentStandstillPantograph: `https://data-interop.era.europa.eu/maxCurrentStandstillPantograph`,
    minimumContactWireHeight: `https://data-interop.era.europa.eu/minimumContactWireHeight`,
    maximumContactWireHeight: `https://data-interop.era.europa.eu/maximumContactWireHeight`,
    contactStripMaterial: `https://data-interop.era.europa.eu/contactStripMaterial`,
    typeVersionNumber: `https://data-interop.era.europa.eu/typeVersionNumber`,
    vehicleSeries: `https://data-interop.era.europa.eu/vehicleSeries`,
    vehicleNumber: `https://data-interop.era.europa.eu/vehicleNumber`,
    vehicleType: `https://data-interop.era.europa.eu/vehicleType`,
    isQuietRoute: `https://data-interop.era.europa.eu/isQuietRoute`,
    operationalRestriction: `https://data-interop.era.europa.eu/operationalRestriction`,
    autorisedCountry: `https://data-interop.era.europa.eu/authorizedCountry`,
    tenClassification: `https://data-interop.era.europa.eu/tenClassification`,
    elementA: `https://data-interop.era.europa.eu/elementA`,
    elementB: `https://data-interop.era.europa.eu/elementB`,
    elementPart: `https://data-interop.era.europa.eu/elementPart`,
    navigability: `https://data-interop.era.europa.eu/navigability`,
    length: `https://data-interop.era.europa.eu/length`,
    trackId: `https://data-interop.era.europa.eu/trackId`,
    notApplicable: `https://data-interop.era.europa.eu/notApplicable`,
    notYetAvailable: `https://data-interop.era.europa.eu/notYetAvailable`,
    solNature: `https://data-interop.era.europa.eu/solNature`,
    track: `https://data-interop.era.europa.eu/track`,
    linkedTo: `https://data-interop.era.europa.eu/linkedTo`,
    partOf: `https://data-interop.era.europa.eu/partOf`,
    
    staticAxleLoadWorkingOrder: `https://data-interop.era.europa.eu/staticAxleLoadWorkingOrder`, // 4.5.3.1
    staticAxleLoadNormalPayload: `https://data-interop.era.europa.eu/staticAxleLoadNormalPayload`, //4.5.3.2
    staticAxleLoadExceptionalPayload: `https://data-interop.era.europa.eu/staticAxleLoadExceptionalPayload`, //4.5.3.3
    
    
    maximumSpeedAndCantDeficiency: `https://data-interop.era.europa.eu/maximumSpeedAndCantDeficiency`, // ERA TV 4.6.4
    maximumPermittedSpeed: `https://data-interop.era.europa.eu/maximumPermittedSpeed`, 
    cantDeficiency: `https://data-interop.era.europa.eu/cantDeficiency`,
    
    minimumVerticalRadius: `https://data-interop.era.europa.eu/minimumVerticalRadius`,
    minimumConvexVerticalRadius: `https://data-interop.era.europa.eu/minimumConvexVerticalRadius`,
    minimumConcaveVerticalRadius: `https://data-interop.era.europa.eu/minimumConcaveVerticalRadius`,
    
    
    
}

export const CLS = {
    notElectrified: `https://data-interop.era.europa.eu/concepts/contact-line-systems/rinf/40`
}

export const ESS = {
    autonomous: `https://data-interop.era.europa.eu/concepts/energy-supply-systems/eratv/autonomous`
}

export const NAVIGS = {
    AB: `https://data-interop.era.europa.eu/concepts/navigabilities/AB`,
    BA: `https://data-interop.era.europa.eu/concepts/navigabilities/BA`,
    Both: `https://data-interop.era.europa.eu/concepts/navigabilities/Both`,
    None: `https://data-interop.era.europa.eu/concepts/navigabilities/None`
}

export const URI_LENGTH = {
    nationalLine: `https://data-interop.era.europa.eu/functionalInfrastructure/nationalLines/`.length,
    operationalPoint: `https://data-interop.era.europa.eu/functionalInfrastructure/operationalPoints/`.length,
}
