import React, { useState, useEffect, useCallback } from "react";

import { Modal, SelectPicker, Panel, List, Tag, IconButton, Loader, FlexboxGrid, Message } from "rsuite";

import { Export } from "./Export.js";

import {
	SPARQL_ENDPOINT,
	NAMED_KG_RINF,
	BASE_URI
} from "../../config/config.js";


import {
	EraIcon
} from "../../styles/Icon.js";

import load_details_query from "./queries/load_details.sparql";

import { era, rdfs, skos, rdf, qudt, addPrefixes, removePrefixes } from "../../utils/NameSpace.js";


const prefixes = {

	"http://www.w3.org/1999/02/22-rdf-syntax-ns#": "rdf:",
	"http://www.w3.org/2000/01/rdf-schema#": "rdfs:",
	"http://data.europa.eu/949/": "era:",
	"http://www.opengis.net/ont/geosparql#": "geosparql:",
	"http://www.w3.org/2003/01/geo/wgs84_pos#": "wgs84:"
}


const excludedProperties = [

	"http://www.w3.org/1999/02/22-rdf-syntax-ns#type",
	"http://data.europa.eu/949/hasAbstraction",
	"http://www.w3.org/2003/01/geo/wgs84_pos#location",
	"http://data.europa.eu/949/notYetAvailable",
	"http://data.europa.eu/949/notApplicable",
	//"http://data.europa.eu/949/opStart",
	//"http://data.europa.eu/949/opEnd",
	//"http://www.w3.org/2000/01/rdf-schema#label"

]

import WebworkerPromise from "webworker-promise";

const TransformURI = (uri) => {

	if (uri.startsWith("http://data.europa.eu/949/")) {

		return BASE_URI + "describe#" + encodeURIComponent(uri);

	} else {

		return uri;

	}


}

const PrettyValue = ({ element, property, query_executor, store_executor }) => {

	let value = element.value;

	const [content, setContent] = useState();

	useEffect(() => {

		processData(element);

	}, [element]);


	const processData = useCallback(async (element) => {


		let type = element.type;
		let result;

		//console.log(element);

		// skos:prefLabel

		if (type === "typed-literal" && element.datatype === "http://www.w3.org/2001/XMLSchema#boolean") {

			if (value === "0") {

				value = "False"

				setContent(<Tag color={"red"}>{value}</Tag>);

			} else {

				value = "True"

				setContent(<Tag color={"green"}>{value}</Tag>);

			}




		} else {

			if (type === "typed-literal" && element.datatype === "http://www.w3.org/2001/XMLSchema#anyURI" && value.startsWith("http")) {

				setContent(<a href={TransformURI(value)}>{value}</a>);

			} else {

				if (type === "uri") {

					// Is SKOS?

					let labels = await store_executor.exec("query", {
						data: { s: value, p: skos.prefLabel, o: null }
					});

					if (labels && labels.length != 0) {

						for (let label of labels) {

							if ((label.object?.language !== undefined && label.object?.language == "en") || label.object?.language === undefined) {

								setContent(<a target="_blank" href={TransformURI(value)}><Tag>{label.object.value}</Tag></a>);

							}

						}

					} else {

						setContent(<a target="_blank" href={TransformURI(value)}>{value}</a>);

					}

				} else {


					let unit = null;


					let units = (await store_executor.exec("query", {
						data: { s: property, p: era.unitOfMeasure, o: null }
					}));

					if (units.length > 0) {

						let symbols = (await store_executor.exec("query", {
							data: { s: units[0].object.value, p: qudt.symbol, o: null }
						}));

						let labels = (await store_executor.exec("query", {
							data: { s: units[0].object.value, p: rdfs.label, o: null }
						}));

						let eng_label;

						if (labels.length > 0) {

							for (let label of labels) {

								if (label.object?.language == "en") {

									eng_label = label.object.value;

								}

							}

						}

						if (symbols.length > 0) {

							let symbol = symbols[0].object.value;

							unit = { "symbol": symbol, "label": eng_label }

						}

					}

					if (unit !== null) {

						//console.log(property, element, value, unit);

						setContent(value + " " + unit.symbol);

					} else {

						//console.log(property, element, value, unit);

						setContent(value);

					}

				}

			}
		}



	}, []);

	return (<List.Item key={element.value}>{content}</List.Item>);

}

const PrettySKOS = ({ ref_property, elements, query_executor, store_executor }) => {

	const [header, setHeader] = useState();
	const [valueItems, setValueItems] = useState([]);

	useEffect(() => {

		processData(ref_property, elements);

	}, [ref_property, elements]);


	const processData = useCallback(async (ref_property, elements) => {

		let refPropName = ref_property.replace("http://data.europa.eu/949/", "");

		let types = await store_executor.exec("query", {
			data: { s: ref_property, p: rdfs.label, o: null }
		});

		if (types && types.length != 0) {

			refPropName = types[0].object.value;

		}

		setHeader(refPropName);

		let items = [];

		for (let element of elements) {

			//console.log("SKOS", element);

			items.push(<PrettyValue key={element.value} element={element} query_executor={query_executor} store_executor={store_executor} />);

		}

		setValueItems(items);



	}, []);

	return (
		<Panel key={ref_property} header={header} bordered style={{ "marginTop": "5px" }}>
			<List>{valueItems}</List>
		</Panel>
	);
}

const PrettyDetails = ({ uri, ref_property, query_executor, store_executor, excluded, style }) => {

	//const [type, setType] = useState();

	const [data, setData] = useState();
	const [header, setHeader] = useState();
	const [loaded, setLoaded] = useState(false);

	const [dataItems, setDataItems] = useState([]);

	const [exportData, setExportData] = useState({ enabled: false });


	let new_excluded = [...excluded];

	if (uri.startsWith("http://data.europa.eu/949/")) {

		new_excluded.push(uri);

	}


	useEffect(() => {

		setData(undefined);
		setHeader(undefined);
		setDataItems([]);

		getData(uri);

	}, [uri]);

	useEffect(() => {

		if (data) {

			setDataItems([]);
			processData(data, uri);

		}

	}, [data]);

	const getData = useCallback(async (uri) => {

		let results;

		//console.log("Get data for", uri);

		let query = load_details_query.replaceAll("${{DETAILS_URI}}", uri);

		query = query.replaceAll("${{NAMED_KG_RINF}}", NAMED_KG_RINF);

		let payload = { data: { endpoint: SPARQL_ENDPOINT, query: query } }

		results = await query_executor.exec("load_details", payload);

		//console.log(uri, results);

		setData(results.bindings);

	}, []);

	const exportItem = (element, type) => {



		let task = { type: type, "elements": [element] }

		//console.log("Export", task);

		setExportData({ enabled: true, data: [task] });

	}

	const onCloseExport = () => {

		setExportData({ enabled: false });
	}


	const processData = useCallback(async (data, uri) => {

		//console.log(uri);

		let propertyList = {};

		let propertyListValues = {};

		let className;
		let classRealName;
		let refPropName;
		let itemLabel = uri.replace("http://data.europa.eu/949/", "");

		if (data.length == 0) {

			//console.log("Empty object?");

			setLoaded(true);

		} else {

			for (let element of data) {

				if (element.p.value === rdf.type) {

					let types = await store_executor.exec("query", {
						data: { s: element.o.value, p: rdfs.label, o: null }
					});

					if (types && types.length != 0) {

						className = types[0].object.value;
						classRealName = element.o.value;

					}

					if (element.s_deref) {

						uri = element.s_deref.value;

					}

				}

				if (element.p.value === rdfs.label) {

					itemLabel = element.o.value;

				}

			}

			if (ref_property) {

				let types = await store_executor.exec("query", {
					data: { s: ref_property, p: rdfs.label, o: null }
				});

				if (types && types.length != 0) {

					refPropName = types[0].object.value;

				}

				if (classRealName == "http://data.europa.eu/949/OperationalPoint" || classRealName == "http://data.europa.eu/949/SectionOfLine") {

					setHeader(<>{className}: <a target="_blank" href={TransformURI(uri)}>
						<Tag color={"blue"}>{itemLabel}</Tag></a> <a target="_blank" href={ref_property}><Tag color={"cyan"}>as property: {refPropName}</Tag></a>
						<IconButton onClick={() => exportItem(uri, classRealName)} style={{ marginLeft: "10px" }} icon={<EraIcon faName={"file-export"} style={null} />} appearance="primary" color="green" size="xs">Export</IconButton>
					</>);

				} else {

					setHeader(<>{className}: <a target="_blank" href={TransformURI(uri)}><Tag color={"blue"}>{itemLabel}</Tag></a> <a target="_blank" href={TransformURI(ref_property)}><Tag color={"cyan"}>as property: {refPropName}</Tag></a></>);

				}



			} else {

				if (classRealName == "http://data.europa.eu/949/OperationalPoint" || classRealName == "http://data.europa.eu/949/SectionOfLine") {

					setHeader(<>{className}: <a target="_blank" href={TransformURI(uri)}>
						<Tag color={"blue"}>{itemLabel}</Tag></a>
						<IconButton onClick={() => exportItem(uri, classRealName)} style={{ marginLeft: "10px" }} icon={<EraIcon faName={"file-export"} style={null} />} appearance="primary" color="green" size="xs">Export</IconButton>
					</>);

				} else {

					setHeader(<>{className}: <a target="_blank" href={TransformURI(uri)}><Tag color={"blue"}>{itemLabel}</Tag></a></>);

				}

			}

			for (let element of data) {

				if (!(element.p.value in propertyListValues)) {

					propertyListValues[element.p.value] = [];

				}

				propertyListValues[element.p.value].push(element.o);

				let index = await store_executor.exec("query", {
					data: { s: element.p.value, p: era.rinfIndex, o: null }
				});



				let types = await store_executor.exec("query", {
					data: { s: element.p.value, p: rdf.type, o: null }
				});

				// Default as datatype property

				propertyList[element.p.value] = { "type": "http://www.w3.org/2002/07/owl#DatatypeProperty" }


				if (index && index.length == 1) { //Properties with more than one rinf index are problematic

					propertyList[element.p.value].index = index[0].object.value;
				}

				if (types) {

					for (let type of types) {

						if (type.object.value === "http://www.w3.org/2002/07/owl#ObjectProperty" || type.object.value === "http://www.w3.org/2002/07/owl#DatatypeProperty") {

							propertyList[element.p.value]["type"] = type.object.value;

						}

					}

				} else {

					//console.log(uri, "No types");

				}

			}


			let dataPropertyItems = [];
			let childObjectItems = [];


			const sortByRinfIndex = (a, b) => {

				let result;

				//console.log(a, b);

				if (a[1]["index"] === undefined) {

					result = 1;

				}

				if (b[1]["index"] === undefined) {

					result = -1;

				}

				if (a[1]["index"] !== undefined && b[1]["index"] !== undefined) {

					result = a[1]["index"].localeCompare(b[1]["index"]);

				}

				return result;

			}


			let sortedProperties = Object.entries(propertyList).sort(sortByRinfIndex);

			//console.log(propertyList);

			//propertyList = Object.fromEntries(sortedProperties);

			//console.log(propertyList, sortedProperties);

			let items = [];

			for (let property of sortedProperties) {

				property = property[0];

				if (!excludedProperties.includes(property)) {


					if (propertyList[property].type === "http://www.w3.org/2002/07/owl#ObjectProperty") {

						// We exclude SKOS and NYA/NA

						// http://www.w3.org/2004/02/skos/core#Concept

						let range = await store_executor.exec("query", {
							data: { s: property, p: rdfs.range, o: null }
						});

						if (range && range[0].object.value !== "http://www.w3.org/2004/02/skos/core#Concept") {

							for (let item of propertyListValues[property]) {

								if (!new_excluded.includes(item.value)) {

									items.push(<PrettyDetails key={item.value} ref_property={property} uri={item.value} query_executor={query_executor} store_executor={store_executor} excluded={new_excluded} style={{ "marginTop": "5px" }} />);

								} else {

									//console.log("Excluded", item);

								}

							}

						} else {

							items.push(<PrettySKOS key={property} ref_property={property} elements={propertyListValues[property]} query_executor={query_executor} store_executor={store_executor} style={{ "marginTop": "5px" }} />);

						}


					}

					if (propertyList[property].type === "http://www.w3.org/2002/07/owl#DatatypeProperty") {

						let values = propertyListValues[property];
						let valueItems = [];

						for (let value of values) {

							valueItems.push(<PrettyValue key={value.value} element={value} property={property} query_executor={query_executor} store_executor={store_executor} />);

						}

						let propertyLabel = property;

						for (let prefix in prefixes) {

							propertyLabel = propertyLabel.replace(prefix, prefixes[prefix]);

						}

						let types = await store_executor.exec("query", {
							data: { s: property, p: rdfs.label, o: null }
						});

						if (types.length != 0) {

							propertyLabel = types[0].object.value;

						} else {

							//console.log(property);

						}

						items.push(<Panel key={property} header={propertyLabel} bordered style={{ "marginTop": "5px" }}><List>{valueItems}</List></Panel>);

					}

				}

			}


			setDataItems(items);

			setLoaded(true);

			if (items.length == 0) {

				//console.log("Empty child:", uri);

			}

		}

	}, []);


	return (
		<>
			{(exportData.enabled) && (<Export data={exportData.data} onClose={onCloseExport} />)}
			{(true) && (

				<Panel header={!loaded ? (<Loader size="xs" content={"Loading: " + uri} />) : header} bordered defaultExpanded={!ref_property} collapsible={ref_property !== undefined} style={style}>
					{loaded && (dataItems)}
				</Panel>
			)}
		</>
	);

}



export const ViewDetails = ({ features, query_executor, store_executor, onClose }) => {

	const [open, setOpen] = useState(true);

	const [featureSelected, setFeatureSelected] = useState();

	const [details, setDetails] = useState();

	const [exportData, setExportData] = useState({ enabled: false });

	const [stats, setStats] = useState({ "ops": 0, "sols": 0 });


	useEffect(() => {

		if (features.length == 1) {

			setDetails(features[0].properties.uri);

		} else {

			let newStats = { "ops": 0, "sols": 0 }

			for (let element of features) {

				if (element.properties.type === "op") {

					newStats.ops++;

				}

			}


			for (let element of features) {

				if (element.properties.type === "sol") {

					newStats.sols++;

				}

			}

			setStats(newStats);

		}

	}, [features]);

	const featureSelector = () => {

		let entries = [];
		let entryKeys = [];


		for (let feature of features) {

			const types = {

				"op": "Operational Points",
				"sol": "Sections of Line"

			}

			if (!entryKeys.includes(feature.properties.uri)) {

				entries.push({ "label": feature.properties.label, "value": feature.properties.uri, "type": types[feature.properties.type] });
				entryKeys.push(feature.properties.uri);

			}

		}

		return entries;
	}

	const onSelect = (value, item) => {

		setDetails(value);

	}

	const onExportAll = () => {

		let tasks = [];

		let op_task = { "type": "http://data.europa.eu/949/OperationalPoint", "elements": [] }

		for (let element of features) {

			if (element.properties.type === "op") {

				op_task.elements.push(element.properties.uri)

			}

		}

		let sol_task = { "type": "http://data.europa.eu/949/SectionOfLine", "elements": [] }

		for (let element of features) {

			if (element.properties.type === "sol") {

				sol_task.elements.push(element.properties.uri)

			}

		}

		if (op_task.elements.length > 0) {

			tasks.push(op_task);

		}

		if (sol_task.elements.length > 0) {

			tasks.push(sol_task);

		}

		if (tasks.length > 0) {

			setExportData({ enabled: true, data: tasks });

		}

	}

	const onExportAllClose = () => {

		setExportData({ enabled: false });

	}


	return (

		<Modal
			open={open}
			onClose={onClose}
			size={"full"}
		>
			<Modal.Header>
				<Modal.Title>Railway element details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{(stats.ops + stats.sols) > 1 && (
					<Message bordered showIcon type="info" style={{ marginBottom: "15px" }}>
						<b>Selected:</b> {stats.ops} Operational Points and {stats.sols} Sections of Line
					</Message>
				)}
				{(features.length > 1) && (
					<FlexboxGrid justify="space-between">
						<FlexboxGrid.Item colspan={22} style={{ padding: "0px 5px" }}>
							<SelectPicker
								label="Feature"
								data={featureSelector()}
								groupBy="type"
								onSelect={onSelect}
								cleanable={false}
								block
							/>
						</FlexboxGrid.Item>
						<FlexboxGrid.Item colspan={2}>
							<IconButton

								appearance={"primary"}
								onClick={onExportAll}
								color={"green"}
								icon={<EraIcon faName="file-export" style={null} />}
								block
							>
								Export all
							</IconButton>
						</FlexboxGrid.Item>
					</FlexboxGrid>

				)}
				{details && (<PrettyDetails uri={details} query_executor={query_executor} store_executor={store_executor} excluded={[]} style={{ marginTop: "25px" }} />)}
				{(exportData.enabled) && (<Export data={exportData.data} onClose={onExportAllClose} />)}
			</Modal.Body>
		</Modal>
	);


}

