
import React, { useState, useEffect, Fragment } from "react";
import { InputPicker, IconButton } from "rsuite";
import { selectStyle } from "../../styles/Styles.js";
import { getPhrase, getCurrentLanguage } from "../../utils/Languages.js";

import axios from "axios";

import {
	SEARCH_HINT_API
} from "../../config/config.js";

import {
	EraIcon
} from "../../styles/Icon.js";


export const EraPicker = (props) => {

	let { prefix, picker_type, selected, ...rest } = props;

	let [fetching, setFetching] = React.useState(false);

	let debounce;

	let [elements, setElements] = React.useState([]);

	const renderMenu = (menu) => {
		if (fetching) {
			return (
				<p style={{ padding: 15, color: '#666' }}>
					<EraIcon faName="spinner" spin />{getPhrase('loading', getCurrentLanguage())}...
				</p>
			);

		} else {

			if (elements.length == 0) {
				return (
					<p style={{ padding: 15, color: '#666' }}>
						Type a search query to begin...
					</p>
				);

			} else {

				return menu;

			}
		}
	}


	const onSearchQuery = async (query, event) => {

		debounce = query;

		if (query.length > 2) {

			setFetching(true);

			let response = await axios.post(SEARCH_HINT_API, { "type": picker_type, "query": query, "n_results": 6 });
			let elementsRes = response.data.result;

			if (debounce.length <= query.length) {
				setElements(elementsRes);
				setFetching(false);

				//console.log(">", debounce, query, elementsRes);

			}

			//console.log(debounce, query, elementsRes);
		}

	}

	const renderSelected = (value, item, prefix) => {


		if (!item && selected.id) {

			item = selected;

		}

		let shown_value;

		if (item && item.label) {

			shown_value = item.label;

		} else {

			shown_value = value;

		}

		return (
			<div>
				<span style={{ color: '#575757' }}>
					{getPhrase(prefix, getCurrentLanguage())}:
				</span>{' '}
				{shown_value}
			</div>
		)
	}

	if (prefix === "via" && selected != {}) {

		//console.log("Selected via EP", selected);

	}

	return (

		<InputPicker

			cleanable={true}

			style={selectStyle}
			placeholder={getPhrase(prefix, getCurrentLanguage())}

			onClean={() => clear(prefix)}
			onSearch={onSearchQuery}

			data={elements}

			renderValue={(value, item) => renderSelected(value, item, prefix)}
			renderMenu={renderMenu}

			value={selected.id || null}

			{...rest}

		></InputPicker>

	);

}

