import React, { useState, useEffect, useContext, useCallback } from "react";

import {
  Modal,
  SelectPicker,
  Panel,
  List,
  Tag,
  IconButton,
  Loader,
  FlexboxGrid,
  Message,
} from "rsuite";

import { Export } from "./Export.js";

import moment from "moment";

import rinf_indexes from "./misc/indexes.json";

import { SPARQL_ENDPOINT, NAMED_KG_RINF } from "../../config/config.js";

import { EraIcon } from "../../styles/Icon.js";

import load_details_query from "./queries/load_details.sparql";

import {
  era,
  rdfs,
  skos,
  rdf,
  qudt,
  addPrefixes,
  removePrefixes,
} from "../../utils/NameSpace.js";

import WebworkerPromise from "webworker-promise";

const toHashURL = (url) => {
  return "#" + encodeURIComponent(url);
};

import { StoreContext } from "./Main.js";
import { URIContext } from "./Main.js";

// Aux functions

const sortByRinfIndex = (a, b) => {
  let result;

  //console.log(a, b);

  if (a[1]["index"] === undefined) {
    result = 1;
  }

  if (b[1]["index"] === undefined) {
    result = -1;
  }

  if (a[1]["index"] === undefined && b[1]["index"] === undefined) {
    let a_parts = a[0].split("/").at(-1);
    let b_parts = b[0].split("/").at(-1);

    result = a_parts.localeCompare(b_parts);
  }

  if (a[1]["index"] !== undefined && b[1]["index"] !== undefined) {
    result = a[1]["index"].localeCompare(b[1]["index"]);
  }

  return result;
};

const getLabel = async (store_executor, entity, lang) => {
  let label_lang = "en";

  if (lang !== undefined) {
    label_lang = lang;
  }

  let label = entity;

  try {
    // Check different label sources

    let label_props = [rdfs.label, skos.prefLabel];
    let done = false;

    for (let label_prop of label_props) {
      let labels1 = await store_executor.exec("query", {
        data: {
          destination: "data",
          query: { s: entity, p: label_prop, o: null },
        },
      });

      let labels2 = await store_executor.exec("query", {
        data: {
          destination: "metadata",
          query: { s: entity, p: label_prop, o: null },
        },
      });

      let labels = labels1.concat(labels2);

      console.log("Q", entity, label_prop, labels);

      let labels_lang = [];

      for (let label_case of labels) {
        if (label_case.object?.language == label_lang) {
          labels_lang.push(label_case.object.value);
        }
      }

      if (labels_lang.length > 0) {
        label = labels_lang.join(" / ");
        break;
      }

      for (let label_case of labels) {
        if (label_case.object?.language === undefined) {
          labels_lang.push(label_case.object.value);
        }
      }

      if (labels_lang.length > 0) {
        label = labels_lang.join(" / ");
        break;
      }
    }
  } catch (e) {
    console.log(e);
  }

  return label;
};

const Units = ({ property }) => {
  const [unit, setUnit] = useState();

  const { store_executor } = useContext(StoreContext);

  useEffect(() => {
    processData(property);
  }, [property]);

  const processData = useCallback(async (property) => {
    let units = await store_executor.exec("query", {
      data: {
        destination: "metadata",
        query: { s: property, p: era.unitOfMeasure, o: null },
      },
    });

    //console.log("Unit", property, units);
    //console.log("Unit", property, units);

    if (units.length > 0) {
      let symbols = await store_executor.exec("query", {
        data: {
          destination: "metadata",
          query: { s: units[0].object.value, p: qudt.symbol, o: null },
        },
      });

      /*
			let labels = (await store_executor.exec("query", {
				data: {
					"destination": "metadata", "query": { s: units[0].object.value, p: rdfs.label, o: null }
				}
			}));
	
			let eng_label;
	
			if (labels.length > 0) {
	
				for (let label of labels) {
	
					if (label.object?.language == "en") {
	
						eng_label = label.object.value;
	
					}
	
				}
	
			}
	
			*/

      if (symbols.length > 0) {
        let symbol = symbols[0].object.value;

        //console.log("Unit", property, symbol);

        //unit = {"symbol": symbol, "label": eng_label}

        setUnit(<Tag size="sm">(unit: {symbol})</Tag>);
      }
    }
  });

  return <>{unit}</>;
};

const RinfIndex = ({ uri, property, store_executor }) => {
  const [index, setIndex] = useState();

  useEffect(() => {
    processData(uri, property);
  }, [uri, property]);

  const processData = useCallback(async (uri, property) => {
    //console.log("INDEX", uri, property);

    // Index defined in the ontology

    let indexes = await store_executor.exec("query", {
      data: {
        destination: "metadata",
        query: { s: property, p: era.rinfIndex, o: null },
      },
    });

    if (indexes && indexes.length == 1) {
      setIndex(
        <Tag size="sm" key={indexes[0].object.value}>
          {indexes[0].object.value}
        </Tag>
      );
    }

    // Index desambiguation from root class and path

    if (indexes && indexes.length > 1) {
      let root_class = await store_executor.exec("query", {
        data: {
          destination: "data",
          query: { s: era.ExtraMetadata, p: era.hasRootClass, o: null },
        },
      });

      if (root_class.length > 0) {
        let indexes_path = await store_executor.exec("query", {
          data: {
            destination: "data",
            query: { s: era.ExtraMetadata, p: era.hasInPath, o: null },
          },
        });

        for (let index_path of rinf_indexes) {
          if (
            index_path["root"] == root_class[0].object.value &&
            index_path["property"] == property
          ) {
            //console.log("INDEX", root_class[0].object.value, property, indexes_path, index_path["path"]);

            // Set class still not available in Firefox

            const a = index_path["path"];
            const b = indexes_path.map((x) => {
              return x.object.value;
            });

            //console.log(a, b, a.filter((x) => !b.includes(x)).length, b.filter((x) => !a.includes(x)).length);

            if (
              a.filter((x) => !b.includes(x)).length == 0 &&
              b.filter((x) => !a.includes(x)).length == 0 &&
              a.length == b.length
            ) {
              setIndex(
                <Tag size="sm" key={index_path["index"]}>
                  {index_path["index"]}
                </Tag>
              );
            }
          }
        }
      }

      /*if(indexes && indexes.length != 0){
			
				indexes = indexes.map((x) => {return(<Tag size="sm" key={x.object.value}>{x.object.value}</Tag>)});
				
			}*/
    }
  });

  return <>{index}</>;
};

const PropertyHead = ({ uri, property, inverse, store_executor }) => {
  const [header, setHeader] = useState();

  useEffect(() => {
    processData(uri, property);
  }, [uri, property]);

  const processData = useCallback(async (uri, property) => {
    let header = property;

    let types = await store_executor.exec("query", {
      data: {
        destination: "metadata",
        query: { s: property, p: rdfs.label, o: null },
      },
    });

    if (types && types.length != 0) {
      header = types[0].object.value;
    }

    setHeader(
      <>
        <a href={toHashURL(property)}>{header}</a>
        <RinfIndex
          uri={uri}
          property={property}
          store_executor={store_executor}
        />
      </>
    );
  });

  return (
    <>
      {inverse ? (
        <>
          {" "}
          is <b>{header}</b> of
        </>
      ) : (
        <b>{header}</b>
      )}
    </>
  );
};

const BooleanProperty = ({ data, property, store_executor }) => {
  const [content, setContent] = useState();
  const [datatype, setDatatype] = useState();

  useEffect(() => {
    processData(data);
  }, [data]);

  const processData = useCallback(async (data) => {
    if (data.value === "0" || data.value === "false") {
      setContent(<Tag color={"red"}>False</Tag>);
    } else {
      if (data.value === "1" || data.value === "true") {
        setContent(<Tag color={"green"}>True</Tag>);
      } else {
        setContent(<Tag color={"grey"}>Unknown</Tag>);
      }
    }

    const xsd = "http://www.w3.org/2001/XMLSchema#";

    setDatatype(
      <Tag size="sm">
        <a href={toHashURL(xsd + "boolean")}>xsd:boolean</a>
      </Tag>
    );
  });

  return (
    <List.Item key={data.value}>
      {content} {datatype}
    </List.Item>
  );
};

const IntegerProperty = ({ data, property, store_executor }) => {
  const [content, setContent] = useState();
  const [datatype, setDatatype] = useState();

  useEffect(() => {
    processData(data);
  }, [data]);

  const processData = useCallback(async (data) => {
    setContent(<>{data.value}</>);

    const xsd = "http://www.w3.org/2001/XMLSchema#";

    setDatatype(
      <Tag size="sm">
        <a href={toHashURL(xsd + "integer")}>xsd:integer</a>
      </Tag>
    );
  });

  return (
    <List.Item key={data.value}>
      {content} <Units property={property} store_executor={store_executor} />{" "}
      {datatype}{" "}
    </List.Item>
  );
};

const FloatProperty = ({ data, property, store_executor }) => {
  const [content, setContent] = useState();
  const [datatype, setDatatype] = useState();

  useEffect(() => {
    processData(data);
  }, [data]);

  const processData = useCallback(async (data) => {
    setContent(<>{data.value}</>);

    const xsd = "http://www.w3.org/2001/XMLSchema#";

    setDatatype(
      <Tag size="sm">
        <a href={toHashURL(xsd + "double")}>xsd:double</a>
      </Tag>
    );
  });

  return (
    <List.Item key={data.value}>
      {content} <Units property={property} store_executor={store_executor} />{" "}
      {datatype}
    </List.Item>
  );
};

const DateProperty = ({ data, property, store_executor }) => {
  const [content, setContent] = useState();
  const [datatype, setDatatype] = useState();

  useEffect(() => {
    processData(data);
  }, [data]);

  const processData = useCallback(async (data) => {
    let raw_value = data.value.split("-");

    let value, aprox;

    try {
      let formatter = new Intl.DateTimeFormat(navigator.language || "de-DE");
      let date = new Date(raw_value[0], raw_value[1] - 1, raw_value[2]);
      aprox = moment(date).fromNow().toString();
      value = formatter.format(date);
    } catch (e) {
      //console.log(e);
      value = data.value;
    }

    if (aprox) {
      setContent(
        <>
          {value} <Tag size="sm">(aprox: {aprox})</Tag>
        </>
      );
    } else {
      setContent(<>{value}</>);
    }

    const xsd = "http://www.w3.org/2001/XMLSchema#";

    setDatatype(
      <Tag size="sm">
        <a href={toHashURL(xsd + "date")}>xsd:date</a>
      </Tag>
    );
  });

  return (
    <List.Item key={data.value}>
      {content} {datatype}
    </List.Item>
  );
};

const URIProperty = ({ data, property, store_executor }) => {
  const [content, setContent] = useState();
  const [datatype, setDatatype] = useState();

  useEffect(() => {
    processData(data);
  }, [data]);

  const processData = useCallback(async (data) => {
    if (data.value.startsWith("http://data.europa.eu/949/")) {
      setContent(<a href={toHashURL(data.value)}>{data.value}</a>);
    } else {
      setContent(
        <>
          <a href={data.value} target="_blank">
            {data.value}
          </a>
          <EraIcon
            faName={"right-from-bracket"}
            style={{ marginLeft: "10px" }}
          />
        </>
      );
    }

    const xsd = "http://www.w3.org/2001/XMLSchema#";

    setDatatype(
      <Tag size="sm">
        <a href={toHashURL(xsd + "anyURI")}>xsd:anyURI</a>
      </Tag>
    );
  });

  return (
    <List.Item key={data.value}>
      {content} {datatype}
    </List.Item>
  );
};

const ObjectProperty = ({ data, property, store_executor }) => {
  const [content, setContent] = useState();

  useEffect(() => {
    processData(data);
  }, [data]);

  const processData = useCallback(async (data) => {
    //console.log("Object prop", data, property);

    if (data.value.startsWith("vb")) {
      data.value = data.value.replace("vb", "nodeID://b");
    }

    let label = await getLabel(store_executor, data.value, "en");

    setContent(<a href={toHashURL(data.value)}>{label}</a>);
  });

  return <List.Item key={data.value}>{content}</List.Item>;
};

const StringProperty = ({ data, property, store_executor }) => {
  const [lang, setLang] = useState();
  const [content, setContent] = useState();

  useEffect(() => {
    processData(data);
  }, [data]);

  const processData = useCallback(async (data) => {
    //console.log("String data", data);

    if (data.language) {
      setContent(
        <>
          {data.value} <Tag size="sm">(lang: {data.language})</Tag>
        </>
      );
    } else {
      setContent(<>{data.value}</>);
    }
  });

  return <List.Item key={data.value}>{content}</List.Item>;
};

const PropertyValue = ({ element, property, store_executor }) => {
  let value = element.value;

  const [content, setContent] = useState(<></>);

  useEffect(() => {
    processData(element);
  }, [element]);

  const processData = useCallback(async (element) => {
    let type = element.type;
    let result;

    //console.log("Element", element, element.datatype);

    if (element.isAnonymous !== undefined) {
      // Not sure about this

      element.value = "nodeID://" + element.value.replace("vb", "b");

      setContent(
        <ObjectProperty
          data={element}
          property={property}
          store_executor={store_executor}
        />
      );

      return;
    }

    if (element.datatype) {
      if (
        element.datatype.value === "http://www.w3.org/2001/XMLSchema#boolean"
      ) {
        setContent(
          <BooleanProperty
            data={element}
            property={property}
            store_executor={store_executor}
          />
        );
        return;
      }

      if (
        element.datatype.value === "http://www.w3.org/2001/XMLSchema#integer" ||
        element.datatype.value === "http://www.w3.org/2001/XMLSchema#int"
      ) {
        setContent(
          <IntegerProperty
            data={element}
            property={property}
            store_executor={store_executor}
          />
        );
        return;
      }

      if (
        element.datatype.value === "http://www.w3.org/2001/XMLSchema#double" ||
        element.datatype.value === "http://www.w3.org/2001/XMLSchema#float"
      ) {
        setContent(
          <FloatProperty
            data={element}
            property={property}
            store_executor={store_executor}
          />
        );
        return;
      }

      if (element.datatype.value === "http://www.w3.org/2001/XMLSchema#date") {
        setContent(
          <DateProperty
            data={element}
            property={property}
            store_executor={store_executor}
          />
        );
        return;
      }

      if (
        element.datatype.value === "http://www.w3.org/2001/XMLSchema#anyURI"
      ) {
        setContent(
          <URIProperty
            data={element}
            property={property}
            store_executor={store_executor}
          />
        );
        return;
      }
    } else {
      if (
        element.value.startsWith("http://") ||
        element.value.startsWith("https://")
      ) {
        setContent(
          <URIProperty
            data={element}
            property={property}
            store_executor={store_executor}
          />
        );
      } else {
        setContent(
          <StringProperty
            data={element}
            property={property}
            store_executor={store_executor}
          />
        );
      }
    }
  }, []);

  return content;
};

const SKOSProperty = ({ uri, elements, property, store_executor }) => {
  const [header, setHeader] = useState();
  const [valueItems, setValueItems] = useState([]);

  useEffect(() => {
    processData(property, elements);
  }, [property, elements]);

  const processData = useCallback(async (property, elements) => {
    let items = [];

    for (let element of elements) {
      //console.log("SKOS", element);

      items.push(
        <ObjectProperty
          key={element.value}
          data={element}
          property={property}
          store_executor={store_executor}
        />
      );
    }

    setValueItems(items);
  }, []);

  return (
    <Panel
      key={property}
      header={
        <PropertyHead
          uri={uri}
          property={property}
          store_executor={store_executor}
        />
      }
      bordered
      style={{ marginTop: "5px" }}
    >
      <List>{valueItems}</List>
    </Panel>
  );
};

const PropertyPanel = ({ element, store_executor }) => {
  ////
};

// Inverse properties

const InverseProperty = ({ uri, property, store_executor }) => {
  const [header, setHeader] = useState();
  const [valueItems, setValueItems] = useState([]);

  useEffect(() => {
    processData(uri, property);
  }, [uri, property]);

  const processData = useCallback(async (uri, property) => {
    let subjects = await store_executor.exec("query", {
      data: {
        destination: "data",
        query: { s: null, p: property, o: uri },
      },
    });

    //console.log(uri, property, subjects);

    setHeader(
      <>
        {" "}
        is{" "}
        <i style={{ textDecoration: "underline", color: "#666" }} size="lg">
          {property}
        </i>{" "}
        of
      </>
    );

    let items = [];

    for (let subject of subjects) {
      //console.log(subject.subject.value);

      items.push(
        <ObjectProperty
          key={subject.subject.value}
          data={subject.subject}
          property={property}
          store_executor={store_executor}
        />
      );
    }

    setValueItems(items);
  }, []);

  return (
    <Panel
      key={"inv_" + property}
      header={
        <PropertyHead
          uri={uri}
          property={property}
          store_executor={store_executor}
          inverse
        />
      }
      bordered
      style={{ marginTop: "5px" }}
    >
      <List>{valueItems}</List>
    </Panel>
  );
};

const InversePropertyPanel = ({ uri, data, store_executor }) => {
  const [loaded, setLoaded] = useState(false);

  const [inverseDataItems, setInverseDataItems] = useState([]);

  useEffect(() => {
    if (data) {
      setInverseDataItems([]);
      //console.log("Inverse data", data);
      processData(uri, data);
    }
  }, [data]);

  const processData = useCallback(async (uri, data) => {
    let inversePropertyList = {};
    let inversePropertyListValues = {};

    for (let element of data) {
      if (!(element.predicate.value in inversePropertyListValues)) {
        inversePropertyListValues[element.predicate.value] = [];
      }

      inversePropertyListValues[element.predicate.value].push(element.object);

      let index = await store_executor.exec("query", {
        data: {
          destination: "metadata",
          query: { s: element.predicate.value, p: era.rinfIndex, o: null },
        },
      });

      let types = await store_executor.exec("query", {
        data: {
          destination: "metadata",
          query: { s: element.predicate.value, p: rdf.type, o: null },
        },
      });

      // Default as datatype property

      inversePropertyList[element.predicate.value] = {
        type: "http://www.w3.org/2002/07/owl#DatatypeProperty",
      };

      if (index && index.length == 1) {
        //Properties with more than one rinf index are problematic

        inversePropertyList[element.predicate.value].index =
          index[0].object.value;
      }

      if (types) {
        for (let type of types) {
          if (
            type.object.value ===
              "http://www.w3.org/2002/07/owl#ObjectProperty" ||
            type.object.value ===
              "http://www.w3.org/2002/07/owl#DatatypeProperty"
          ) {
            inversePropertyList[element.predicate.value]["type"] =
              type.object.value;
          }
        }
      }
    }

    let inverseSortedProperties =
      Object.entries(inversePropertyList).sort(sortByRinfIndex);

    let inverseItems = [];

    for (let property of inverseSortedProperties) {
      property = property[0];

      //console.log("Inverse property:", property);

      inverseItems.push(
        <InverseProperty
          key={property}
          uri={uri}
          property={property}
          store_executor={store_executor}
        />
      );
    }

    setInverseDataItems(inverseItems);

    setLoaded(true);
  });

  return (
    <Panel
      header={<h5>Inverse properties</h5>}
      defaultExpanded
      bordered
      style={{ marginTop: "15px" }}
    >
      {loaded && inverseDataItems.length > 0 && inverseDataItems}
      {loaded && inverseDataItems.length == 0 && (
        <p>No inverse properties found</p>
      )}
    </Panel>
  );
};

const PrettyDetails = ({ uri, store_executor }) => {
  let ref_property = true;
  let style = {};

  const [header, setHeader] = useState();
  const [subHeader, setSubHeader] = useState();
  const [loaded, setLoaded] = useState(false);

  const [inverseData, setInverseData] = useState([]);

  const [dataItems, setDataItems] = useState([]);
  const [inverseDataItems, setInverseDataItems] = useState([]);

  const [exportable, setExportable] = useState(false);
  const [exportData, setExportData] = useState({ enabled: false });
  const [classURI, setClassURI] = useState();

  useEffect(() => {
    //console.log("Rendering", uri)

    if (uri) {
      setHeader(undefined);
      setDataItems([]);
      processData(uri);
    }
  }, [uri]);

  const exportItem = (element, type) => {
    let task = { type: type, elements: [element] };

    //console.log("Export", task);

    setExportData({ enabled: true, data: [task] });
  };

  const onCloseExport = () => {
    setExportData({ enabled: false });
  };

  const processData = useCallback(async (uri) => {
    //console.log(uri);

    let propertyList = {};
    let propertyListValues = {};

    let className;
    let classURI;
    let refPropName;
    let itemLabel = uri.replace("http://data.europa.eu/949/", "");

    let data = await store_executor.exec("query", {
      data: {
        destination: "data",
        query: { s: uri, p: null, o: null },
      },
    });

    //console.log(store_executor);

    let dataInv = await store_executor.exec("query", {
      data: {
        destination: "data",
        query: { s: null, p: null, o: uri },
      },
    });

    setInverseData(dataInv);

    //console.log(dataInv);

    if (data.length == 0 && dataInv.length == 0) {
      setLoaded(true);
    } else {
      for (let element of data) {
        if (element.predicate.value === rdf.type) {
          className = element.object.value;

          if (
            element.object.value ===
              "http://data.europa.eu/949/OperationalPoint" ||
            element.object.value === "http://data.europa.eu/949/SectionOfLine"
          ) {
            setExportable(true);
          } else {
            setExportable(false);
          }

          setClassURI(element.object.value);

          let types = await store_executor.exec("query", {
            data: {
              destination: "metadata",
              query: { s: element.object.value, p: rdfs.label, o: null },
            },
          });

          let typesSkos = await store_executor.exec("query", {
            data: {
              destination: "metadata",
              query: { s: element.object.value, p: rdfs.label, o: null },
            },
          });

          //console.log(types);

          if (types && types.length != 0) {
            className = types[0].object.value;

            setSubHeader(
              <>
                Type:{" "}
                <a href={"#" + encodeURIComponent(element.object.value)}>
                  {className}
                </a>{" "}
              </>
            );
          }

          if (element.s_deref) {
            uri = element.s_deref.value;
          }
        }
      }

      itemLabel = await getLabel(store_executor, uri);

      setHeader(<>Resource: {itemLabel} </>);

      // Direct properties

      for (let element of data) {
        if (!(element.predicate.value in propertyListValues)) {
          propertyListValues[element.predicate.value] = [];
        }

        propertyListValues[element.predicate.value].push(element.object);

        /*let index = await store_executor.exec("query", {
					data: {"destination": "metadata", "query":   { s: element.predicate.value, p: era.rinfIndex, o: null}
				}})*/ let index;

        let indexes = await store_executor.exec("query", {
          data: {
            destination: "metadata",
            query: { s: element.predicate.value, p: era.rinfIndex, o: null },
          },
        });

        if (indexes && indexes.length == 1) {
          index = indexes[0].object.value;
        }

        // Index desambiguation from root class and path

        if (indexes && indexes.length > 1) {
          let root_class = await store_executor.exec("query", {
            data: {
              destination: "data",
              query: { s: era.ExtraMetadata, p: era.hasRootClass, o: null },
            },
          });

          if (root_class.length > 0) {
            let indexes_path = await store_executor.exec("query", {
              data: {
                destination: "data",
                query: { s: era.ExtraMetadata, p: era.hasInPath, o: null },
              },
            });

            for (let index_path of rinf_indexes) {
              if (
                index_path["root"] == root_class[0].object.value &&
                index_path["property"] == element.predicate.value
              ) {
                const a = index_path["path"];
                const b = indexes_path.map((x) => {
                  return x.object.value;
                });

                if (
                  a.filter((x) => !b.includes(x)).length == 0 &&
                  b.filter((x) => !a.includes(x)).length == 0 &&
                  a.length == b.length
                ) {
                  index = index_path["index"];
                }
              }
            }
          }
        }

        let types = await store_executor.exec("query", {
          data: {
            destination: "metadata",
            query: { s: element.predicate.value, p: rdf.type, o: null },
          },
        });

        // Default as datatype property

        propertyList[element.predicate.value] = {
          type: "http://www.w3.org/2002/07/owl#DatatypeProperty",
        };
        propertyList[element.predicate.value].index = index;

        //console.log(element.predicate.value, index);

        if (types) {
          for (let type of types) {
            if (
              type.object.value ===
                "http://www.w3.org/2002/07/owl#ObjectProperty" ||
              type.object.value ===
                "http://www.w3.org/2002/07/owl#DatatypeProperty"
            ) {
              propertyList[element.predicate.value]["type"] = type.object.value;
            }
          }
        } else {
          //console.log(uri, "No types");
        }
      }

      //console.log("Properties", propertyList);

      let sortedProperties = Object.entries(propertyList).sort(sortByRinfIndex);

      propertyList = Object.fromEntries(sortedProperties);

      //console.log(propertyList, sortedProperties);

      let items = [];

      for (let property of sortedProperties) {
        property = property[0];

        // Skipped properties

        if (
          property === rdfs.label ||
          property === skos.prefLabel ||
          property === rdf.type
        ) {
          continue;
        }

        if (
          propertyList[property].type ===
          "http://www.w3.org/2002/07/owl#ObjectProperty"
        ) {
          let range = await store_executor.exec("query", {
            data: {
              destination: "metadata",
              query: { s: property, p: rdfs.range, o: null },
            },
          });

          if (
            range.length > 0 &&
            range[0].object.value !==
              "http://www.w3.org/2004/02/skos/core#Concept"
          ) {
            let values = propertyListValues[property];
            let valueItems = [];

            for (let value of values) {
              valueItems.push(
                <ObjectProperty
                  key={value.value}
                  data={value}
                  property={property}
                  store_executor={store_executor}
                />
              );
            }

            //console.log("OP", property);

            items.push(
              <Panel
                key={property}
                header={
                  <PropertyHead
                    uri={uri}
                    property={property}
                    store_executor={store_executor}
                  />
                }
                bordered
                style={{ marginTop: "5px" }}
              >
                <List>{valueItems}</List>
              </Panel>
            );
          } else {
            items.push(
              <SKOSProperty
                uri={uri}
                key={property}
                property={property}
                elements={propertyListValues[property]}
                store_executor={store_executor}
              />
            );
          }
        }

        if (
          propertyList[property].type ===
          "http://www.w3.org/2002/07/owl#DatatypeProperty"
        ) {
          let values = propertyListValues[property];
          let valueItems = [];

          for (let value of values) {
            valueItems.push(
              <PropertyValue
                key={value.value}
                element={value}
                property={property}
                store_executor={store_executor}
              />
            );
          }

          let propertyLabel = property;

          let types = await store_executor.exec("query", {
            data: {
              destination: "metadata",
              query: { s: property, p: rdfs.label, o: null },
            },
          });

          if (types.length != 0) {
            propertyLabel = types[0].object.value;
          }

          //console.log("DP", property);

          items.push(
            <Panel
              key={property}
              header={
                <PropertyHead
                  uri={uri}
                  property={property}
                  store_executor={store_executor}
                />
              }
              bordered
              style={{ marginTop: "5px" }}
            >
              <List>{valueItems}</List>
            </Panel>
          );
        }
      }
      setDataItems(items);
      setLoaded(true);
    }
  }, []);

  return (
    <>
      {exportData.enabled && (
        <Export data={exportData.data} onClose={onCloseExport} />
      )}
      {header && (
        <>
          <h3 style={{ margin: "15px 0px 0px 15px", color: "#000" }}>
            {header}
            {exportable && (
              <IconButton
                onClick={() => exportItem(uri, classURI)}
                style={{ marginLeft: "10px" }}
                icon={<EraIcon faName={"file-export"} style={null} />}
                appearance="primary"
                color="green"
                size="xs"
              >
                Export
              </IconButton>
            )}
          </h3>
          <h5 style={{ margin: "0px 0px 0px 25px", color: "#777" }}>
            <i>{subHeader}</i>
          </h5>

          <Panel
            header={<h5>Properties</h5>}
            defaultExpanded
            bordered
            style={{ marginTop: "25px" }}
          >
            {loaded && dataItems.length > 0 && dataItems}
            {loaded && dataItems.length == 0 && <p>No properties found.</p>}
          </Panel>

          <InversePropertyPanel
            uri={uri}
            data={inverseData}
            store_executor={store_executor}
          />
        </>
      )}

      {!header && (
        <h3 style={{ margin: "15px 0px 0px 15px", color: "#000" }}>
          Resource not found: <a href={uri}>{uri}</a>
        </h3>
      )}
    </>
  );
};

export const DescribeDetails = ({ uri, store_executor }) => {
  const [details, setDetails] = useState();
  const [exportData, setExportData] = useState({ enabled: false });

  const onExportAll = () => {
    let tasks = [];

    let op_task = {
      type: "http://data.europa.eu/949/OperationalPoint",
      elements: [],
    };

    for (let element of features) {
      if (element.properties.type === "op") {
        op_task.elements.push(element.properties.uri);
      }
    }

    let sol_task = {
      type: "http://data.europa.eu/949/SectionOfLine",
      elements: [],
    };

    for (let element of features) {
      if (element.properties.type === "sol") {
        sol_task.elements.push(element.properties.uri);
      }
    }

    if (op_task.elements.length > 0) {
      tasks.push(op_task);
    }

    if (sol_task.elements.length > 0) {
      tasks.push(sol_task);
    }

    if (tasks.length > 0) {
      setExportData({ enabled: true, data: tasks });
    }
  };

  const onExportAllClose = () => {
    setExportData({ enabled: false });
  };

  return (
    <>
      <PrettyDetails uri={uri} store_executor={store_executor} />
      {exportData.enabled && (
        <Export data={exportData.data} onClose={onExportAllClose} />
      )}
    </>
  );
};
