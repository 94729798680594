import { Container, Message, Nav, Panel } from "rsuite";
import React, { useEffect, useState, useContext } from "react";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { UnauthenticatedLayout } from "../Authentication/UnauthenticatedLayout.js";
import { UnauthorizedLayout } from "../Authentication/UnauthorizedLayout.js";

import { appRoleMap } from "../../azure/authConfig.js";

import { DatasetsPanel } from "./DatasetsPanel.js";
import { ReferenceDocumentsPanel } from "./ReferenceDocumentsPanel.js";

import { AuthContext } from "./../App/Main.js";

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Alert = ({ type, text, showAlert, setShowAlert }) => {

	// TO BE CHANGED INTO TOAST


	if (!showAlert) return null

	const onClose = () => {
		setShowAlert(false)
	}

	return (
		<Message style={{ marginBottom: "15px" }} showIcon type={type} onClose={onClose} closable={true} duration={10000}>
			{text}
		</Message>
	)
}

const DatasetManagementComponent = () => {
	const [activeTab, setActiveTab] = useState("");
	const [alertType, setAlertType] = useState();
	const [alertMessage, setAlertMessage] = useState();
	const [showAlert, setShowAlert] = useState(false)


	const [isAuthenticated, setIsAuthenticated] = useState();
	const [userInfo, setUserInfo] = useState();
	const [userRoles, setUserRoles] = useState();
	const [activeRole, setActiveRole] = useState();

	const { auth } = useContext(AuthContext);


	useEffect(() => {

		if (auth && auth.auth) {

			//console.log(auth);

			setUserInfo(auth.info.userInfo);
			setUserRoles(auth.info.userRoles);
			setActiveRole(auth.info.activeRole);

			setIsAuthenticated(true);

		}

	}, [auth]);




	useEffect(() => {
		setActiveTab(sessionStorage.getItem("activeTab") ?? "datasetsTab")
	}, [activeTab])

	const handleTabSelect = (eventKey) => {
		sessionStorage.setItem("activeTab", eventKey)
		setActiveTab(eventKey);
	};

	function handleAlert(type, message) {
		setAlertType(type)
		setAlertMessage(message)
		setShowAlert(true)
	}

	function renderTable() {
		if (activeTab === "datasetsTab") {
			return (
				<DatasetsPanel dataAssetType={"datasets"} setAlert={(type, message) => handleAlert(type, message)} />
			)
		}
		else if (activeTab === "documentsTab") {
			return (
				<ReferenceDocumentsPanel dataAssetType={"documents"} setAlert={(type, message) => handleAlert(type, message)} />
			)
		}
	}

	return (
		<>
			{
				(appRoleMap.get(activeRole) === "NRE"
					|| appRoleMap.get(activeRole) === "IM") ?
					<>
						<Alert
							type={alertType}
							text={alertMessage}
							showAlert={showAlert}
							setShowAlert={setShowAlert}
						/>

						<Nav appearance="tabs" activeKey={activeTab} onSelect={handleTabSelect} justified
							onChange={renderTable}
						>
							<Nav.Item eventKey="datasetsTab" style={{ textAlign: 'center' }}>
								Datasets
							</Nav.Item>

							<Nav.Item eventKey="documentsTab" style={{ textAlign: 'center' }}>
								Reference documents
							</Nav.Item>
						</Nav>
						{renderTable()}
					</>
					:
					<UnauthorizedLayout />
			}
		</>
	);
}

export default class DatasetManagement extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Container style={{ margin: "2rem" }}>
				<Panel header={<h3>Datasets manager</h3>}>

				</Panel>
				<Panel>

					<UnauthenticatedTemplate>
						<UnauthenticatedLayout />
					</UnauthenticatedTemplate>

					<AuthenticatedTemplate>
						<DatasetManagementComponent />
					</AuthenticatedTemplate>

				</Panel>
			</Container>
		)
	}

}
